body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'IBM Plex Sans', sans-serif;
}

.navbar-container .ui.menu {
  background-color: #000000;
}

.navbar-container {
  background-color: #000000;
}

.navbar-container .ui.menu .item {
  color: white;
}

.navbar-container .ui.menu .active.item:hover {
  color: #ecf0f1;
}

.navbar-container .ui.menu .active.item {
  color: white;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

body {
  height: 100%;
  margin-bottom: 60px; /* Margin bottom by footer height */
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}

